'use client';

import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import {
    useCallback,
    useState,
} from 'react';
import styles from './BorrowersMainSection.module.scss';
import Section from '../../../server/ui/Section/Section';
import Image from '../../../server/Image/Image';
import HtmlMarkup from '@app/components/server/common/HtmlMarkup/HtmlMarkup';
import BecomeBorrowerForm from '@app/components/client/borrowers/BecomeBorrowerForm/BecomeBorrowerForm';
import GoToSectionButton from '../../common/GoToSectionButton/GoToSectionButton';
import FormAchievements from '../../common/FormAchievements/FormAchievements';

interface IProps {
    /**
     * Заголовок страницы
     */
    title: string;
    /**
     * Описание страницы
     */
    description: string;
}

/**
 * Тело страницы заёмщиков
 */
export default function BorrowersMainSection ({
    title,
    description,
}: IProps) {
    const [loaded, setLoaded] = useState(false);

    const didLoaded = useCallback(() => {
        setLoaded(true);
    }, []);

    return (
        <>
            <div className={styles['mobile-view']}>
                <Section
                    stretch={['horizontally', 'bottom']}
                    animation="none"
                >
                    <div className={styles['container']}>
                        <div className={styles['mobile-header']}>
                            <HtmlMarkup tag="div" className={styles['form__heading']} markup={title} />
                            <HtmlMarkup tag="p" className={styles['form__description']} markup={description} />
                            <FormAchievements className={styles['achievements']} />
                        </div>
                        <div className={styles['mobile-button-wrapper']}>
                            <BecomeBorrowerForm width={250} />
                        </div>
                        <div className={styles['image-wrapper--mobile']}>
                            <Image
                                src="/assets/borrowers-main-mobile.png"
                                alt=""
                                fill
                                priority
                                className={styles['test']}
                                onLoad={didLoaded}
                                quality={loaded ? 100 : 10}
                            />
                        </div>
                    </div>
                </Section>
            </div>

            <div className={styles['desktop-view']}>
                <Section
                    animation="none"
                >
                    <div className={styles['container']}>
                        <div className={styles['form']}>
                            <HtmlMarkup tag="h1" className={styles['form__heading']} markup={title} />
                            <div className={styles['form__subsection']}>
                                <HtmlMarkup tag="p" className={styles['form__description']} markup={description} />
                                <div className={buildClassNames(styles, ['form__input', 'form__input--large'])}>
                                    <BecomeBorrowerForm block />
                                    <GoToSectionButton
                                        text="Рассчитать займ"
                                        outline
                                        size="big"
                                        block
                                        targetSelector="[data-anchor='calculator']"
                                    />
                                </div>
                                <div className={buildClassNames(styles, ['form__input', 'form__input--small'])}>
                                    <BecomeBorrowerForm size="normal" block />
                                    <GoToSectionButton
                                        text="Рассчитать заём"
                                        outline
                                        size="normal"
                                        block
                                        targetSelector="[data-anchor='calculator']"
                                    />
                                </div>
                            </div>
                        </div>
                        <FormAchievements className={styles['achievements']} />
                        <div className={styles['image-wrapper']}>
                            <Image
                                src="/assets/zajm-ip-ooo-na-kraudlendingovoj-platforme-jetlend.png"
                                title="Займ ип ооо на краудлендинговой платформе JetLend"
                                alt="Займ ип ооо на краудлендинговой платформе JetLend"
                                fill
                                sizes="(max-width: 950px) 400px, (max-width: 1250px) 500px, (max-width: 1650px) 700px, 900px"
                                priority
                                onLoad={didLoaded}
                                quality={loaded ? 100 : 10}
                            />
                        </div>
                    </div>
                </Section>
            </div>
        </>
    );
};
