'use client';

import Button, { ButtonProps } from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import {
    isMobile,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import { useCallback } from 'react';

export interface IProps extends Omit<ButtonProps, 'onClick'|'href'|'external'> {
    targetSelector: string;
}

const GoToSectionButton: React.FC<IProps> = props => {
    const {
        targetSelector,
        ...buttonProps
    } = props;

    const { width } = useWindowSize();

    const isMobileSize = isMobile(width);

    const didButtonClicked = useCallback(() => {
        const element = document.querySelector(targetSelector);

        window.scrollTo({
            behavior: 'smooth',
            top: element.getBoundingClientRect().top + window.scrollY - (isMobileSize ? 72 : 82),
        });
    }, [isMobileSize, targetSelector]);

    return (
        <Button
            {...buttonProps}
            onClick={didButtonClicked}
        />
    );
};

export default GoToSectionButton;